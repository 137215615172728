import "../scss/style.scss";
import { reviewSlider } from "./modules/reviewSlider.js";
import { partnersSlider } from "./modules/partnersSlider.js";
import { headerActivities } from "./modules/headerActivities.js";
import { headerLanguage } from "./modules/headerLanguage.js";
import { headerRehabilitation } from "./modules/headerRehabilitation.js";
import { burgerButton } from "./modules/burgerButton.js";

document.addEventListener("DOMContentLoaded", () => {
  reviewSlider();
  partnersSlider();
  headerActivities();
  headerLanguage();
  headerRehabilitation();
  burgerButton();
});
