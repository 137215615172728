import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';


function reviewSlider() {
  if (!document.querySelector(".reviews__slider")) return false;
    new Splide('#image-slider', {
    type      : 'loop',
    perPage: 2,
    perMove: 1,
    autoplay  : false,
    pagination: true,
    arrows: false,
    focus: 1,
    gap: '24px',
    flickMaxPages: 1,
    breakpoints: {
      1023: {
        perPage: 1,
        gap:'16px',
      },
      767: {
        perPage: 1,
      },
    }
    
  }).mount();
}

  
    
export { reviewSlider } ;