function headerRehabilitation() {
  // Get all custom select elements
  let showDropdown = document.querySelectorAll(".heder__item-first-action");

  // Attach click event listeners to each custom select
  showDropdown.forEach(function (show) {
    let openList = show.querySelector(".heder__list-icon");
    let showList = show.querySelector(".heder__list-show");
    let selectItems = show.querySelector(".heder__option");

    // Toggle the dropdown visibility when the select box is clicked
    openList.addEventListener("click", function (event) {
      event.preventDefault();
      if (showList.style.display === "block") {
        showList.style.display = "none";
      } else {
        showList.style.display = "block";
      }
    });

    // Close the dropdown if the user clicks outside of it
    window.addEventListener("click", function (e) {
      if (!show.contains(e.target)) {
        showList.style.display = "none";
      }
    });
  });
}
export { headerRehabilitation };
