import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';

function partnersSlider() {
  if (!document.querySelector(".partners__wrapper")) return false;
    new Splide('.partners__wrapper', {
    type: 'loop',
    perPage: 7 ,
    perMove: 1,
    autoplay: false,
    pagination: true,
    arrows: false,
    breakpoints: {
      640: {
        destroy: 'completely',
      },
    }
  }).mount();
}

export { partnersSlider } ;