function headerLanguage() {
  // Get all custom select elements
  let customSelects = document.querySelectorAll(".heder__language-dropdown");

  // Attach click event listeners to each custom select
  customSelects.forEach(function (select) {
    let selectCurrent = select.querySelector(".heder__language-current");
    let selectItems = select.querySelector(".heder__language-select");
    let options = selectItems.querySelectorAll(".heder__language-option");

    // Toggle the dropdown visibility when the select box is clicked
    selectCurrent.addEventListener("click", function () {
      if (selectItems.style.display === "block") {
        selectItems.style.display = "none";
      } else {
        selectItems.style.display = "block";
      }
    });
    // Set the selected option and hide the dropdown when an option is clicked
    options.forEach(function (option) {
      option.addEventListener("click", function () {
        selectCurrent.textContent = option.textContent;
        selectItems.style.display = "none";
      });
    });

    // Close the dropdown if the user clicks outside of it
    window.addEventListener("click", function (e) {
      if (!select.contains(e.target)) {
        selectItems.style.display = "none";
      }
    });
  });
}
export { headerLanguage };
