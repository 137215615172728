function burgerButton() {
  let burgerButton = document.querySelector(".heder__burger-button");
  let overlayMenu = document.querySelector(".heder__overlay-menu");

  burgerButton.addEventListener("click", function () {
    overlayMenu.classList.toggle("heder__overlay-menu--active");
    burgerButton.classList.toggle("heder__burger-button--active");
  });
}

export { burgerButton };
